import React from "react";
import { Navigation, Pagination, A11y, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from '../Assets/logo.png'
// Import Swiper styles
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { EffectFade } from "swiper";
import { slider } from "../data";

const Slider = () => {
	return (
		<div>
			<Swiper
				// install Swiper modules
				modules={[
					Navigation,
					Pagination,
					Scrollbar,
					A11y,
					EffectFade,
					Autoplay,
				]}
				spaceBetween={10}
				slidesPerView={1}
				autoplay={{ delay: 2500, disableOnInteraction: false }}
				// navigation
				pagination={{ clickable: true }}
				effect="fade"
			>
				{slider.map((slider, index) => {
					return (
						<SwiperSlide key={index}>
							<div>
								<img loading="lazy"  src={slider.src} />
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>

			<img className="logo" src={logo}/>

		</div>
	);
};

export default Slider;
