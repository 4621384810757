import React from "react";
import locate from "../Assets/locate.png";
const Footer = () => {
	return (
		<div className="pt-5">
			<ul className=" d-flex pt-5 justify-content-center align-items-center ">
				<li className=" d-flex  gap-1 ">
					<img className="icon-location " loading="lazy" src={locate} />
					<a
						target="_blank"
						href="https://www.google.com/maps/dir/30.0456862,31.2282952/30.0824764,31.3222149/@30.0637537,31.1920592,12z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0?entry=ttu"
						className="location"
					>
						7 شارع عبد الوهاب القاضي، ارض الجولف، القاهرة
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Footer;
