import React, { useState } from "react";

const CallToAction = () => {
	return (
		<div class={`social-links-fixed`}>
			<ul class="">
				<div className="container-calls mb-3">
					<li class="whatsapp">
						<a href="https://wa.me/+201116389995">
							<span class="fab fa-whatsapp"></span>
						</a>
					</li>
				</div>

				<div className="container-calls">
					<li class="twitter">
						<a href="tel:+201116389995">
							<span class="fas fa-phone"></span>
						</a>
					</li>
				</div>
			</ul>
		</div>
	);
};

export default CallToAction;
