import slider0 from "./Assets/1.png";
import slider1 from "./Assets/2.png";
import slider22 from "./Assets/slider22.jpg";
import slider33 from "./Assets/slider33.webp";

import serve3 from "./Assets/serve3.jpeg";
import serve4 from "./Assets/serve4.jpeg";

export const slider = [
	{
		id: 1,
		src: slider0,
	},
	{
		id: 2,
		src: slider1,
	},
];

export const services = [
	{
		id: 1,
		src: slider33,
		title: "لوسيال",

		desc: "قاعة لوسيال هي أكبر قاعة في فندق لوسيال، وتتسع لـ 700 شخص.	تتميز القاعة بمساحتها الواسعة، وديكوراتها الفخمة، وخدماتها الرائعة.	يوفر فندق لوسيال أفضل خدمات التصوير الفوتوغرافي والفيديو، بالإضافة إلى البوفيه، وشاشات العرض، وعرض النار.",
	},

	{
		id: 2,
		src: slider22,
		title: "ليلتى",

		desc: "تعد من القاعات الساحرة والمتميزة حيث تتسع القاعة لعدد كبير من الضيوف، وتوفر أحدث التجهيزات من أنظمة صوتية وإضاءة متطورة، بالإضافة إلى طاقم عمل متميز يحرص على تقديم أفضل الخدمات.",
	},
	{
		id: 3,
		src: serve3,
		title: "لارين",

		desc: "فندق لوسيال يوفر لكِ قاعة لارين المميزة لحفل زفافكِ، حيث تتسع القاعة الي  200 فرد، وتتميز بديكورات أنيقة وأفضل التجهيزات من صوت وإضاءة وكاميرات تصوير فوتوغرافي وفيديو تواصل معنا للحجز والتعرف على المزيد من الخدمات والمزايا",
	},
	{
		id: 4,
		src: serve4,
		title: "لاتيراس",

		desc: "تعد من القاعات المتميزة وذلك لأنها من قاعات الـ5 نجوم في فندق لوسيال حيث تشتهر ب ديكوراتها المختلفه بالاضافه الى توفيرها لأفضل التجهيزات من حيث الانظمة الصوتية مما يجعلها من القاعات ذو وجهة مثالية لحفلات الزفاف والمؤتمرات.",
	},
];
